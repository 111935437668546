<template>
    <div class="mr-3">
        <associatedOption
                :fieldAttributes="{ name: 'IP Version', associatedOption: localCodes }"
                type="select"
                :fieldAttrInput="{ class: 'required' }"
                v-model="ipVersion"
                :templateContent="result"
                :isValid="isValid"
        ></associatedOption>
    </div>
</template>
<script>
  import associatedOption from "@/commonComponents/associatedOption.vue";
  export default {
    components: { associatedOption },

    props: ["field", "fieldAttributes", "result", "valueLine","isValid"],

    data() {
      return { localCodes: ["ipv4", "ipv6"] };
    },
    computed: {

      ipVersion: {
        get() {
          //console.log(this.valueLine);
           let ipVersion = "";
          if (typeof this.valueLine.ipv4!= "undefined" && this.valueLine.ipv4==true) {
            ipVersion = "ipv4";
          }else if(typeof this.valueLine.ipv6!= "undefined" && this.valueLine.ipv6==true){
            ipVersion = "ipv6";
          }
          return ipVersion;
        },
        set(selected) {
         // console.log(selected);
            if(selected=="ipv4"){
              this.$set(this.valueLine, "ipv6", false);
            }else if(selected=="ipv6"){
              this.$set(this.valueLine, "ipv4", false);
            }
           this.$set(this.valueLine, selected, true);
        },
      },
    },
    methods: {
      updateResponseKey(index, newVal) {
        //console.log( "---",index, newVal);
        this.$set(this.localStoredStep["parameters"], index,newVal  );
        this.$emit("stored-value-changed", this.localStoredStep);
      },

    },
  };
</script>